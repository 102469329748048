const DEFAULT_LOCALE = "en-US";

export function getLocale() {
  if (typeof window === "undefined") {
    return DEFAULT_LOCALE;
  }

  if (navigator.languages != undefined) {
    return navigator.languages[0];
  }
  return navigator.language || (navigator as any).browserLanguage; // "browserLanguage" is IE10- fallback
}
